<template>
    <div>
        <v-container class="my-4 px-4">
            <div class="d-flex px-2 justify-end">
                <listMetaAction
                        :list-key="listKey"
                ></listMetaAction>
            </div>
            <div class="px-2">
                <listSearchBar
                        class="my-4"
                        :listKey="listKey"
                ></listSearchBar>
            </div>
            <div class="d-flex justify-space-between px-4 pb-4">
                <!-- 全選/取消全選 -->
                <div class="d-flex align-center pl-4">
                    <v-checkbox
                            @click="toggleSelectAll"
                            class="absolute"
                            v-model="hasSelectedAll"
                            :disabled="allItemsDisabled"
                    >
                    </v-checkbox>
                    <div class="pl-10">全選</div>
                </div>


                <v-btn
                        small
                        depressed
                        dark
                        @click="saveData"
                        color="#12358E"
                        style="border-radius: 999px"
                >
                    儲存
                </v-btn>
            </div>
            <!-- 條列模式 -->
            <div class="px-4">
                <v-card
                        v-for="(v) in apiData" :key="v.id"
                        outlined
                        class="d-flex justify-space-between align-center px-4"
                >
                    <!-- 主要資料 -->
                    <div class="d-flex justify-center">
                        <v-checkbox
                                @change="toggleSelected(v.id)"
                                :input-value="selected.includes(v.id)"
                                :disabled="isSelectedData(v.id)"
                                class="list-item-checkbox"
                                style="position: relative !important;"
                                :style="{ zIndex: $zIndexConfig.listCardMode }"
                        ></v-checkbox>

                    </div>

                    <div>{{ v.name || v.product.name }}</div>

<!--                    空元件，未來可能放action-->
                    <div></div>
                </v-card>
            </div>
        </v-container>

    </div>

</template>

<script lang="babel" type="text/babel">
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './productMenuCreateItemConfig'

export default {
    mixins: [listMixin],
    data: () => ({
        listKey: 'provider-product-selector',
        meta: {},
        apiData: [],
        hasSelectedData: [],
        selected: []
    }),
    components: {
        // listDataAction: () => import('@/components/list/listDataAction.vue'),
        listSearchBar: () => import('@/components/list/listSearchBar.vue'),
        listMetaAction: () => import('@/components/list/listMetaAction.vue'),
        // listRowMode: () => import('@/components/list/listRowMode'),
        // batchDelete: () => import('@/components/list/listToolbar/batchDelete.vue'),
    },
    computed: {
        providerId() {
            return this.$store.getters['member/providerId']
        },
        menuId() {
            return this.$route.params.target
        },
        createSelectAllButton() {
            if (!this.hasData) return false
            if (!this.hasSelectFeature) return false
            if (this.isPopupMode) return false // module selector模式不使用全選toggle功能
            return true
        },
        hasSelectFeature() {
            return this.$store.getters[`list/${this.listKey}/hasSelectFeature`]
        },
        usedListEmpty() {
            return this.$store.getters[`list/${this.listKey}/usedListEmpty`]
        },
        isPopupMode() {
            return this.$store.getters[`list/${this.listKey}/isPopupMode`]
        },
        selectorSingleMode() {
            return this.$store.getters[`list/${this.listKey}/selectorSingleMode`]
        },
        hasData() {
            return this.$store.getters[`list/${this.listKey}/hasData`]
        },
        targetKey() {
            return this.$store.getters[`list/${this.listKey}/targetKey`]
        },
        listActions() {
            return this.$store.getters[`list/${this.listKey}/actions`]
        },
        // hasSelectedAll() {
        //     return this.$store.getters[`list/${this.listKey}/hasSelectedAll`]
        // },
        listConfig() {
            return this.$store.getters[`list/${this.listKey}/config`]
        },
        listData() {
            return this.$store.getters[`list/${this.listKey}/data`]
        },
        listHasSelected() {
            return this.$store.getters[`list/${this.listKey}/hasSelected`]
        },
        searchKeyword() {
            return this.$store.getters[`list/${this.listKey}/searchKeyword`]
        },
        hasSelectedAll() {
            const selectableData = this.apiData.filter(item => !this.isSelectedData(item.id));
            return selectableData.length > 0 && selectableData.every(item => this.selected.includes(item.id));
        },
        allItemsDisabled() {
            return this.apiData.every(item => this.isSelectedData(item.id));
        }
    },
    created() {
        this.getData()
    },
    watch: {
        searchKeyword: {
            immediate: true,
            handler() {
                this.getData()
            },
        },
    },
    methods: {
        updatePopupSelectData(data) {
            this.$emit('updateData', data)
        },
        async beforeIndex() {
            await Promise.all([])
        },
        async indexApi(params) {
            return await this.$api.collection.productApi.index(this.providerId, params)
        },
        async menuIndexApi(params) {
            return await this.$api.collection.productMenuPivotApi.index(this.providerId, this.menuId, params)
        },
        async createApi(params) {
            return await this.$api.collection.productMenuPivotApi.create(this.providerId, this.menuId, params);
        },
        async deleteApi(target) {
            return await this.$api.collection.productApi.delete(this.providerId, target)
        },
        async patchApi(value, row, config) {
            const target = row.id
            const column = config.key
            return await this.$api.collection.productApi.patch(this.providerId, target, column, value)
        },
        async batchApi(targets, column, value) {
            return await this.$api.collection.productApi.batch(this.providerId, targets, column, value)
        },
        async batchDeleteApi(targets) {
            return await this.$api.collection.productApi.batchDelete(this.providerId, targets)
        },
        getListConfig() {
            return listConfig
        },
        async getData() {
            await this.$store.dispatch(`loading/active`)

            this.apiData = await this.indexApi({pager: 0, q: this.searchKeyword})
            this.hasSelectedData = await this.menuIndexApi({pager: 0})

            await this.$store.dispatch(`loading/close`)
        },
        toggleSelected(id) {
            const index = this.selected.indexOf(id);
            if (index !== -1) {
                this.selected.splice(index, 1);
            } else {
                this.selected.push(id);
            }
        },
        toggleSelectAll() {
            if (this.hasSelectedAll) {
                this.selected = [];
                return;
            }

            this.selected = this.apiData
                .filter(item => !this.isSelectedData(item.id))
                .map(item => item.id);
        },
        async saveData() {
            await this.$store.dispatch(`loading/active`)
            const param = {product_ids: this.selected}
            try {
                await this.createApi(param)
                await this.getData()
                this.$snotify.success(null, this.$t('action.add.success'))
            } catch (err) {
                this.$snotify.error(null, this.$t('action.add.fail'))
                console.error(err)
            } finally {
                // @ts-ignore
                this.selected = []
                await this.$router.push({name: 'product-menu-update'})
                await this.$store.dispatch(`loading/close`)
            }
        },
        isSelectedData(id) {
            return !!this.hasSelectedData.find(item => item.product[0].id === id);
        }
    },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
