import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'

class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.product',
      search: 'product.search_keyword',
      filter: {
        // status: {
        //   label: 'data.status',
        //   type: 'selection',
        //   options: [
        //     {text: '全部', value: null},
        //     {text: vm.$t('action.up'), value: true},
        //     {text: vm.$t('action.down'), value: false}
        //   ],
        // },
      },
      selectedData: row => ({
        id: row.id,
        name: row.name,
        title: row.title,
      }),
      // displayMode: 'table',
      // displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      // card: {
      //   title: row => row.name,
      //   subtitle: row => row.name,
      //   avatarPhoto: row => row.avatar,
      // },
      // table: {
      //   data: <TableDataType>[
      //     {
      //       key: 'name',
      //       label: 'data.name',
      //       type: 'text',
      //     },
      //     {
      //       key: 'price',
      //       label: 'data.price',
      //       type: 'text',
      //       text: (row) => {
      //         const product = row
      //         return product.price * product.per_shipping_count
      //       },
      //     },
      //   ],
      // },
      batch: {
        // delete: {
        //   targetLabel: row => row.name,
        // },
      },
      // metaAction: {
      //   create: {
      //     label: 'action.create',
      //     linkTarget: '_self',
      //     type: 'route',
      //     route: () => ({ name: 'product-create' }),
      //   },
      // },
      // dataAction: {
      //   update: {
      //     label: 'action.edit',
      //     route: row => ({ name: 'product-update', params: { target: row.id } }),
      //     linkTarget: '_self',
      //     color: 'primary'
      //   },
      //   delete: {
      //     removeTarget: row => row.name,
      //   },
      // },
    }

  }
}

export default new listConfig()
